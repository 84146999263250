@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for all browsers */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari and Opera */
}
.no-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}
