.animated-circle {
  animation: drawCircle 5s ease-in forwards;
}

@keyframes drawCircle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes revolve {
  100% {
    transform: rotate(360deg) translateX(0px) rotate(-360deg);
  }
}

.revolve {
  animation: revolve 2s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.visible {
  opacity: 1; /* Fully visible */
  animation: fadeIn 1s ease-in forwards;
}
